import styled from '@emotion/styled'

export const StarsFilledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const StarItemContainer = styled.div`
  position: absolute;
  animation: ${(props) => `filled${props.num}`} ${props => props.time}s infinite;
  /* animation: filled 3s infinite; */
  animation-timing-function: ease-in-out;
  animation-delay: 0s;
  bottom: 0;
  opacity: 0;
  left: ${props => props.startLeft}px;
  bottom: ${props => props.startBottom}px;
  @keyframes ${(props) => `filled${props.num}`} {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0.8;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      left: ${props => props.endLeft}px;
      bottom: ${props => props.moveHeight}px;
    }
  }
  img {
    width: ${props => props.size}px;
    height: auto;
  }
  span {
    font-size: ${props => props.size}px;
  }
`;